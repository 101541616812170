import axios from '../utils/request'

export function get_venue_list(params){
    return axios({
        url:'/get_venue',
        method:'get',
        params
    })
}

export function get_address_poin(params){
    return axios({
        url:'/get_address_poin',
        method:'get',
        params
    })
}

export function add_venue(data){
    return axios({
        url:'/add_venue',
        method:'post',
        data
    })
}

export function del_venue(params){
    return axios({
        url:'/del_venue',
        method:'get',
        params
    })
}

export function edit_venue(data){
    return axios({
        url:'/edit_venue',
        method:'post',
        data
    })
}
export function get_venue_id(params){
    return axios({
        url:'/get_venue_id',
        method:'get',
        params
    })
}



